import { get_breakpoint } from "components/GlobalHelpers";
import { throttled_resize } from "components/GlobalHelpers";

export const ProductsListingBlock = () => {
  if (jQuery(".products-listing-block--slider-mode").length) {
    import(
      /* webpackChunkName: "slick-carousel" */ "slick-carousel/slick/slick.min"
    )
      .then(() => {
        products_listing_block_slider_mode();

        if (get_breakpoint() == "md") {
          products_listing_block_slider_mode();
        } else {
          products_listing_block_slider_mode();
        }

        throttled_resize(250, function() {
          if (get_breakpoint() == "md") {
            products_listing_block_slider_mode();
          } else {
            products_listing_block_slider_mode();
          }
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  function products_listing_block_slider_mode() {
    jQuery(".products-listing-block--slider-mode").each(function() {
      var $block = jQuery(this),
        $slider = jQuery(this).find(".products-listing-block__products-list"),
        arrows_nav = jQuery(this).find(".products-listing-block__slider-nav"),
        slides_to_show = $block.hasClass(
          "products-listing-block--partners-collections"
        )
          ? 2
          : 4;

      if ($slider.hasClass("slick-initialized")) {
        return;
      }
      $slider.slick({
        slide: ".products-listing-block__product-block",
        slidesToShow: slides_to_show,
        slidesToScroll: slides_to_show,
        arrows: true,
        speed: 600,
        dots: false,
        infinite: false,
        // swipeToSlide: true,
        rows: 0,
        appendArrows: arrows_nav,
        responsive: [
          {
            breakpoint: 992,
            settings: "unslick"
          }
        ]
      });
    });
  }
};
