export const FancyBoxImage = () => {
  const restApiUrl = window.coalesseRestApi + "coalesse/v1/FancyBoxImage/";
  const LoadingSpinner = jQuery('<div class="LoadingSpinner"></div>');
  jQuery("body").append(LoadingSpinner);
  jQuery(".ImageModal___enabled img").each(function() {
    const $image = jQuery(this);
    let isNotLinkedImage;
    if ($image.parents("a").length) {
      isNotLinkedImage = false;
    } else {
      isNotLinkedImage = true;
    }

    // only bind if parent isn't an anchor link and not undefined ID
    if (isNotLinkedImage) {
      $image.addClass("cursor-pointer");
      let $img_id = $image.attr("data-id");
      const $img_src = $image.attr("src");
      // check class for attachment id
      if (typeof $img_id === "undefined") {
        const imgClass = jQuery(this).attr("class");
        const capturingRegex = /wp-image-(?<id>\d+)/;
        const found = imgClass.match(capturingRegex);
        if (found.groups.id) {
          $img_id = found.groups.id;
        }
      }
      $image.on("click", function(e) {
        e.preventDefault();

        if (typeof $img_id === "undefined") {
          jQuery.fancybox.open({
            src: $img_src,
            type: "image",
            opts: {
              // baseClass: "FancyBoxImage",
              buttons: ["close", "download"]
            }
          });
        } else {
          jQuery.ajax({
            url: restApiUrl + $img_id,
            method: "GET",
            beforeSend: function() {
              LoadingSpinner.addClass("LoadingSpinner__active");
            },
            success: function(data) {
              const renderHandleBarsTemplate = require("FancyBoxImage/FancyBoxImage.handlebars");

              const template_output = renderHandleBarsTemplate(data);

              jQuery.fancybox.open({
                src: template_output,
                type: "html",
                opts: {
                  // baseClass: "FancyBoxImage",
                  buttons: ["close"],
                  smallBtn: false,
                  toolbar: true,
                  idleTime: 0,
                  btnTpl: {
                    close:
                      '<button data-fancybox-close class="fancybox-button fancybox-button--close FancyBoxImage_CloseBtn" title="{{CLOSE}}">' +
                      '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>' +
                      "</button>"
                  },
                  afterShow: function() {}
                }
              });

              LoadingSpinner.removeClass("LoadingSpinner__active");
            },
            error: function(e) {
              console.log(e);
            }
          });
        }
      });
    }
  });
};
