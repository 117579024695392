export const ProductDrawer = () => {
  jQuery(".product-drawer").each(function() {
    var $block = jQuery(this),
      $top_level_tabs = $block.find(".product-drawer__top-level-tab"),
      $top_level_groups = $block.find(".product-drawer__top-level-group"),
      $sub_level_nav = $block.find(".product-drawer__sub-level-tabs-nav"),
      $sub_level_tabs = $block.find(".product-drawer__sub-level-tab");

    $top_level_tabs.on("click", function() {
      var tab_num = jQuery(this).attr("data-top-level-tab"),
        tab_anchor = jQuery(this).attr("data-tab-anchor"),
        $group_to_open = $top_level_groups.filter(function() {
          var group_num = jQuery(this).attr("data-top-level-group");
          if (group_num == tab_num) {
            return true;
          }
        });
      // if clicking the same tab then collapse and remove active state
      if (jQuery(this).hasClass("product-drawer__top-level-tab--active")) {
        jQuery(this).removeClass("product-drawer__top-level-tab--active");
        $group_to_open.removeClass("product-drawer__top-level-group--active");

        // remove hash from url
        history.replaceState("", document.title, window.location.pathname);
      }
      // if clicking other tabs
      else {
        // add active to clicked tab
        jQuery(this).addClass("product-drawer__top-level-tab--active");

        // remove active from other tabs
        jQuery(this)
          .siblings()
          .removeClass("product-drawer__top-level-tab--active");

        // add active to the clicked tab group
        $group_to_open.addClass("product-drawer__top-level-group--active");

        // remove active from other tab groups
        $top_level_groups
          .not($group_to_open)
          .removeClass("product-drawer__top-level-group--active");

        // add hash to url
        history.replaceState(
          "",
          document.title,
          window.location.pathname + tab_anchor
        );
      }
    });

    $sub_level_tabs.on("click", function() {
      var tab_num = jQuery(this).attr("data-sub-level-tab"),
        $sub_groups = jQuery(this)
          .closest(".product-drawer__top-level-group")
          .find(".product-drawer__sub-level-group"),
        $group_to_open = $sub_groups.filter(function() {
          var group_num = jQuery(this).attr("data-sub-level-group");
          if (group_num == tab_num) {
            return true;
          }
        });
      // add active to clicked tab
      jQuery(this).addClass("product-drawer__sub-level-tab--active");
      jQuery(this)
        .siblings()
        .removeClass("product-drawer__sub-level-tab--active");
      // add active to the clicked tab group
      $group_to_open.addClass("product-drawer__sub-level-group--active");
      // remove active from other tabs groups
      $sub_groups
        .not($group_to_open)
        .removeClass("product-drawer__sub-level-group--active");
    });

    var $first_sub_level_tabs = $sub_level_tabs.filter(function() {
      var tab_num = jQuery(this).attr("data-sub-level-tab");
      if (tab_num == "1") {
        return true;
      }
    });
    $first_sub_level_tabs.trigger("click");

    // hide the sub level tab menu if there is only one item
    $sub_level_nav.each(function() {
      var sub_tab_count = jQuery(this).find(".product-drawer__sub-level-tab")
        .length;
      if (sub_tab_count <= 1) {
        jQuery(this).hide();
      }
    });

    // check for existing hash and make active if it exists
    if (window.location.hash) {
      let url_hash = window.location.hash,
        $tab_to_open = $top_level_tabs.filter(function() {
          var tab_hash = jQuery(this).attr("data-tab-anchor");
          if (url_hash == tab_hash) {
            return true;
          }
        });
      $tab_to_open.trigger("click");
    }
  });
};
