import { GlobalHeader } from "global-header/global-header";
new GlobalHeader();

import { UberMenu } from "uber-menu/uber-menu";
new UberMenu(jQuery(".uber-menu")[0]);

import { BreadCrumbs } from "bread-crumbs/bread-crumbs";
BreadCrumbs();

import { CookiesNotice } from "cookies_notice/cookies_notice";
CookiesNotice();

import { RegionBar } from "region-bar/region-bar";
RegionBar();

import { HeroSlider1 } from "hero-slider-1/hero-slider-1";
HeroSlider1();

import { PostCanvasGridLayout } from "post-canvas-grid-layout/post-canvas-grid-layout";
PostCanvasGridLayout();

import { PostCanvasHero2PostLayout } from "post-canvas-hero-layout/post-canvas-hero-layout";
PostCanvasHero2PostLayout();

import { StatementSlider } from "statement-slider/statement-slider";
StatementSlider();

import { LeadTimesAccordion } from "lead-times-accordion/lead-times-accordion";
LeadTimesAccordion();

import { GravityFormBlock } from "gravity-form-block/gravity-form-block";
GravityFormBlock();

import { WysiwygBlock } from "wysiwyg-block/wysiwyg-block";
WysiwygBlock();

import { ProductsListingBlock } from "products-listing-block/products-listing-block";
ProductsListingBlock();

import { ProductCategoryFilter } from "product-category-filter/product-category-filter";
ProductCategoryFilter();

import { PostsSlider } from "posts-slider/posts-slider";
PostsSlider();

import { ProductTagFilter } from "product-tag-filter/product-tag-filter";
ProductTagFilter();

import { ProductAvailabilityGrid } from "../../components/organism/ProductAvailabilityGrid/ProductAvailabilityGrid";
if (jQuery(".ProductAvailabilityGrid").length) {
  new ProductAvailabilityGrid(jQuery(".ProductAvailabilityGrid"));
}

import { GalleryMasonryGrid } from "gallery-masonry-grid/gallery-masonry-grid";
new GalleryMasonryGrid();

import { GalleryFilterBar } from "gallery-filter-bar/gallery-filter-bar";
GalleryFilterBar();

import { ProductFilesFilter } from "product-files-filter/product-files-filter";
ProductFilesFilter();

import { ProductFilesAlphaList } from "product-files-alpha-list/product-files-alpha-list";
ProductFilesAlphaList();

import { SalesLocationsTabs } from "sales-locations-tabs/sales-locations-tabs";
SalesLocationsTabs();

import { SalesLocationsFilters } from "sales-locations-filters/sales-locations-filters";
SalesLocationsFilters();

import { ProductDrawer } from "product-drawer/product-drawer";
ProductDrawer();

import { FancyBoxImage } from "FancyBoxImage/FancyBoxImage";
FancyBoxImage();
