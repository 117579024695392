import { get_breakpoint } from "components/GlobalHelpers";
import { throttled_resize } from "components/GlobalHelpers";

export const PostCanvasHero2PostLayout = () => {
  // set height of left post on 2 post layout so it matches the height of the right posts
  function post_canvas_hero_2_post_layout() {
    jQuery(".post-canvas-hero-layout--5-posts").each(function() {
      var first_right_block_height = jQuery(this)
          .find(
            ".post-canvas-hero-layout__right .post-canvas-hero-layout__post:first-child"
          )
          .outerHeight(),
        third_right_block_image_height = jQuery(this)
          .find(
            ".post-canvas-hero-layout__right .post-canvas-hero-layout__post:nth-child(3) .post-canvas-block__feat-image"
          )
          .outerHeight(),
        combined_height =
          first_right_block_height + third_right_block_image_height,
        $left_post_block = jQuery(this).find(
          ".post-canvas-hero-layout__left .post-canvas-block__feat-image"
        );

      if (get_breakpoint() == "md") {
        $left_post_block.height(combined_height);
      } else {
        $left_post_block.height("auto");
      }
    });
  }
  post_canvas_hero_2_post_layout();
  throttled_resize(250, function() {
    post_canvas_hero_2_post_layout();
  });
};
