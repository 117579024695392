export const ProductFilesAlphaList = () => {
  jQuery(".product-files-alpha-list").each(function() {
    var $block = jQuery(this),
      $product_title = $block.find(".product-files-alpha-list__product-title");
    $product_title.on("click", function() {
      jQuery(this).toggleClass("product-files-alpha-list__product-title--open");
      jQuery(this)
        .next()
        .slideToggle();
    });
  });
};
