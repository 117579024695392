import { get_url_parameter } from "components/GlobalHelpers";
import { LeavingCurrentSiteModal } from "../LeavingCurrentSiteModal/LeavingCurrentSiteModal";

class ProductAvailabilityGrid {
  $block: JQuery;
  apiUrl: String;
  filterSelect: JQuery;
  select2Filter: JQuery;
  restApiUrl: string;
  renderHandleBarsTemplate: Function;
  loadingSpinner: JQuery;
  handleBarsOutputTarget: JQuery;
  currentSiteId: Number;
  constructor() {
    this.$block = jQuery(".ProductAvailabilityGrid");
    this.currentSiteId = parseInt(this.$block.attr("data-site-id"));
    this.restApiUrl =
      window.coalesseRestApi + "coalesse/v1/ProductInternationalAvailability/";
    this.renderHandleBarsTemplate = require("./ProductAvailabilityGrid_product.hbs");
    this.handleBarsOutputTarget = this.$block.find(
      ".ProductAvailabilityGrid_productsList"
    );
    this.loadingSpinner = this.$block.find(".ProductAvailabilityGrid_spinner");
    this.filterSelect = this.$block.find(
      ".ProductAvailabilityGrid_filterSelect"
    );

    import(/* webpackChunkName: "select2" */ "select2")
      .then(() => {
        this.buildSelect2();
        this.urlQueryUpdateFilter();
        this.linkBinding();
      })
      .catch(err => {
        console.error(err);
      });
  }

  private linkBinding = () => {
    jQuery(document).on("click", ".ProductBlock_linkWrap", e => {
      const productSiteId = parseInt(
        jQuery(e.currentTarget).attr("data-product-site-id")
      );

      if (productSiteId !== this.currentSiteId) {
        const targetUrl = jQuery(e.currentTarget).attr("href");
        e.preventDefault();
        LeavingCurrentSiteModal(targetUrl);
      }
    });
  };
  private buildSelect2() {
    const dropdown_container = jQuery(
      '<div class="select2-container__dropdown-wrap"></div>'
    );
    // append dropdown container and use so we can target the dropdown with styling
    this.filterSelect.after(dropdown_container);

    this.select2Filter = this.filterSelect.select2({
      theme: "select2-styles",
      // dropdownPosition: "below",
      dropdownParent: dropdown_container
    });

    this.setSelect2Binds();
  }

  private setSelect2Binds = () => {
    this.select2Filter.on("change", () => {
      let $filter_value = this.select2Filter.val();
      this.updateProductsList(Number($filter_value));
      if ($filter_value == 0) {
        history.replaceState("", document.title, window.location.pathname);
      } else {
        history.replaceState(
          "",
          document.title,
          window.location.pathname + "?country=" + $filter_value
        );
      }
    });
  };

  private urlQueryUpdateFilter = () => {
    const url_country_parameter = get_url_parameter("country");

    // check if there is a set url query parameter
    if (url_country_parameter) {
      let search_for_option = this.filterSelect.find(
        'option[value="' + url_country_parameter + '"]'
      );
      // if the option exists
      if (search_for_option.length) {
        this.select2Filter.val(url_country_parameter); // Select the option with a value of '1'
        this.select2Filter.trigger("change");
      }
      // if not then just load all products
      else {
        this.updateProductsList(0);
      }
    }
    // else load all products
    else {
      this.updateProductsList(0);
    }
  };

  private updateProductsList = (country_id: Number) => {
    jQuery.ajax({
      url: this.restApiUrl + country_id,
      method: "GET",
      beforeSend: () => {
        this.loadingSpinner.show();
      },
      success: data => {
        this.handleBarsOutputTarget.html(this.renderHandleBarsTemplate(data));
        this.loadingSpinner.hide();
      },
      error: function(e) {
        console.log(e);
      }
    });
  };
}

export { ProductAvailabilityGrid };
