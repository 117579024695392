import Mmenu from "mmenu-js/src/mmenu";
require("mmenu-js/src/_polyfills/api.foreach.js");
require("mmenu-js/src/_polyfills/api.matches.js");
require("mmenu-js/src/_polyfills/api.closest.js");
require("mmenu-js/src/_polyfills/dom.prepend.js");
require("mmenu-js/src/_polyfills/dom.append.js");
require("mmenu-js/src/_polyfills/dom.before.js");
require("mmenu-js/src/_polyfills/dom.remove.js");

import {
  TimelineLite,
  TweenLite,
  CSSPlugin,
  AttrPlugin,
  Power1,
  Power4
} from "gsap/all";

const plugins = [TweenLite, CSSPlugin, AttrPlugin];

export class GlobalHeader {
  $block: JQuery;
  constructor() {
    this.$block = jQuery(".global-header");
    document.addEventListener("DOMContentLoaded", () => {
      this.buildMmenu();
    });
    this.buildDropdownAnimation();
    this.buildSearchAnimation();
  }

  private buildMmenu(): void {
    const $menu = new Mmenu("#mmenu-trigger", {
      extensions: [
        "position-right",
        "pagedim-black",
        "position-front",
        "fx-panels-slide-0"
      ],
      offCanvas: {
        menu: {
          page: {
            selector: ".mmenu-page-container"
          }
        }
      }
    });

    const api = $menu.API;
    const $mobileMenuToggle = jQuery(".global-header__menu-toggle");
    const $firstLevelMenuTitle = jQuery("#mm-1 .mm-navbar__title");

    // add active state
    api.bind("open:finish", function() {
      $mobileMenuToggle.addClass("global-header__menu-toggle--open");
    });

    // remove active state
    api.bind("close:finish", function() {
      $mobileMenuToggle.removeClass("global-header__menu-toggle--open");
    });

    // open the menu
    $mobileMenuToggle.click(function(evnt) {
      evnt.preventDefault();
      api.open();
    });

    // remove the main menu title
    $firstLevelMenuTitle.html("");

    // close menu when clicking first level title element
    $firstLevelMenuTitle.on("click", function() {
      api.close();
    });
  }

  private buildDropdownAnimation(): void {
    const dropdownTimeline = new TimelineLite({ paused: true });
    const $dropdownBg = this.$block.find(".global-header__dropdown-bg");
    const $dropdownBgShadow = this.$block.find(
      ".global-header__dropdown-shadow"
    );

    dropdownTimeline
      .addLabel("start", "+=0")
      .fromTo(
        $dropdownBg,
        0.2,
        { height: 0 },
        { height: "20.125rem", ease: Power1.easeOut },
        "start"
      )
      .to(
        $dropdownBgShadow,
        0.5,
        { display: "block", autoAlpha: 0.3 },
        "start"
      );

    // attach animation to .global-header
    this.$block.data("dropdown_animation", dropdownTimeline);
  }

  private buildSearchAnimation(): void {
    // search toggle animation

    const $searchBtn = this.$block.find(".global-header__search-toggle");
    const $searchBar = this.$block.find(".global-header__search-bar");
    const $searchInput = this.$block.find(".global-header__search-input");
    const searchToggleAnimation = new TimelineLite({ paused: true });

    // search animation timeline
    searchToggleAnimation
      .addLabel("start")
      .to($searchBar, 0, { display: "block" })
      .fromTo(
        $searchBar,
        0.4,
        { width: 0 },
        { width: "12.5rem", ease: Power4.easeOut },
        "start"
      )
      .to($searchBar, 0.2, { autoAlpha: 1 }, "-=0.3")
      .eventCallback("onComplete", function() {
        $searchInput.focus();
      })
      .eventCallback("onReverseComplete", function() {
        $searchInput.blur();
      });

    // attach timeline to dom so we can trigger it later
    $searchBar.data("gsap-animation", searchToggleAnimation);

    $searchBtn.on("click", function() {
      if ($searchBtn.hasClass("global-header__search-bar--open")) {
        $searchBtn.removeClass("global-header__search-bar--open");
        jQuery(this)
          .next(".global-header__search-bar")
          .data("gsap-animation")
          .reverse();
      } else {
        $searchBtn.addClass("global-header__search-bar--open");
        jQuery(this)
          .next(".global-header__search-bar")
          .addClass("wtf");

        jQuery(this)
          .next(".global-header__search-bar")
          .data("gsap-animation")
          .play();
      }
    });
  }
}
