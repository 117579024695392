import { get_breakpoint } from "components/GlobalHelpers";

export const GalleryFilterBar = () => {
  jQuery(".gallery-filter-bar").each(function() {
    // return if desktop because function just scrolls to tab on mobile
    if (get_breakpoint() == "md") return;

    let $block = jQuery(this),
      $top_level_tab_container = $block.find(
        ".gallery-filter-bar__top-level-tabs-nav"
      ),
      $top_level_tab_active = $block.find(
        ".gallery-filter-bar__top-level-tab--active"
      ),
      $sub_level_tab_active_container = $block.find(
        ".gallery-filter-bar__sub-level-tabs-nav--active"
      ),
      $sub_level_tab_active = $block.find(
        ".gallery-filter-bar__sub-level-tab--active"
      );

    // scroll to element on mobile if there are active tabs and mobile viewport
    jQuery(window).load(function() {
      if ($top_level_tab_active.length) {
        $top_level_tab_container.scrollLeft(
          $top_level_tab_active.position().left
        );
      }
      if ($sub_level_tab_active.length) {
        $sub_level_tab_active_container.scrollLeft(
          $sub_level_tab_active.position().left
        );
      }
    });
  });
};
