var $ = require("jquery");
var jQueryBridget = require("jquery-bridget");
var InfiniteScroll = require("infinite-scroll");

export const PostCanvasGridLayout = () => {
  // make Infinite Scroll a jQuery plugin
  jQueryBridget("infiniteScroll", InfiniteScroll, $);

  jQuery(".post-canvas-grid-layout").each(function() {
    var $feed = jQuery(this).find(".post-canvas-grid-layout__grid"),
      $status = jQuery(this).find(".blog-feed__status"),
      $next = jQuery(this).find("a.next");

    if ($next.length) {
      $feed.on("last.infiniteScroll", function() {
        $status.hide();
      });
      $feed.infiniteScroll({
        path: ".post-canvas-grid-layout a.next",
        append: ".post-canvas-grid-layout__grid .post-canvas-grid-layout__post",
        history: false,
        debug: true,
        scrollThreshold: false,
        button: ".post-canvas-grid-layout__view-more-btn"
      });
    } else {
      $status.hide();
    }
  });
};
