export const PostsSlider = () => {
  jQuery(".posts-slider").each(function() {
    var $slider = jQuery(this).find(".posts-slider__slider"),
      $arrows_nav = jQuery(this).find(".posts-slider__slider-nav-btns-wrap");
    import(
      /* webpackChunkName: "slick-carousel" */ "slick-carousel/slick/slick.min"
    )
      .then(() => {
        $slider.slick({
          infinite: false,
          slidesToShow: 4,
          slide: ".posts-slider__slide",
          rows: 0,
          variableWidth: true,
          swipeToSlide: true,
          appendArrows: $arrows_nav,
          responsive: [
            {
              breakpoint: 990,
              settings: {
                // centerMode: true,
                // centerPadding: '50px',
                slidesToShow: 1,
                swipeToSlide: true,
                adaptiveHeight: true,
                variableWidth: true
              }
            }
          ]
        });
      })
      .catch(err => {
        console.error(err);
      });
  });
};
