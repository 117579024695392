export const ProductFilesFilter = () => {
  jQuery(".product-files-filter").each(function() {
    var $block = jQuery(this),
      $categories = $block.find(".product-files-filter__category-item"),
      $product_files = jQuery(".product-files-alpha-list__product-file"),
      $product_files_container = jQuery(
        ".product-files-alpha-list__product-files"
      ),
      $letters = $block.find(".product-files-filter__alpha-filter-letter");

    // hide products if there are no files
    var hide_product_if_empty = function() {
      $product_files_container.each(function() {
        // hide product if no files
        if (jQuery(this).children().length == 0) {
          jQuery(this)
            .parent()
            .addClass("js-hide");
        }
        // hide product if have files but all hidden
        else if (jQuery(this).children(":not(.js-hide)").length == 0) {
          jQuery(this)
            .parent()
            .addClass("js-hide");
        }
        // else show
        else {
          jQuery(this)
            .parent()
            .removeClass("js-hide");
        }
      });
    };

    hide_product_if_empty();

    // show/hide match product category
    $categories.on("click", function() {
      var $cat_value = jQuery(this).attr("data-category");

      // show/hide status on tab
      jQuery(this).addClass("product-files-filter__category-item--active");
      jQuery(this)
        .siblings()
        .removeClass("product-files-filter__category-item--active");

      // show all files
      if ($cat_value == "all") {
        $product_files.removeClass("js-hide");
      } else {
        // get list of matches product files
        let $product_files_to_show = $product_files.filter(function() {
          var file_cat = jQuery(this).attr("date-file-category");
          if (file_cat == $cat_value) {
            return true;
          }
        });

        // show the matched product files
        $product_files_to_show.removeClass("js-hide");

        // hide non-matched product files
        $product_files.not($product_files_to_show).addClass("js-hide");
      }

      hide_product_if_empty();
    });

    // scroll to on letter click
    $letters.on("click", function(e) {
      e.preventDefault();
      if (
        jQuery(this).hasClass(
          "product-files-filter__alpha-filter-letter--disabled"
        )
      ) {
        return;
      }
      var $hash = jQuery(this.hash),
        header_height = jQuery(".top-nav-wrap").outerHeight();
      jQuery("html,body").animate({
        scrollTop: $hash.offset().top - header_height - 50
      });
    });

    // build existing letter group array
    var $existing_letter_groups = jQuery(
        ".product-files-alpha-list__product-letter-group"
      ),
      $existing_letter_groups_array = [];
    $existing_letter_groups.each(function() {
      $existing_letter_groups_array.push(jQuery(this)[0].id);
    });

    // look through the letters in filter and mark disabled if it doesn't exist
    $letters.each(function() {
      var letter = jQuery(this)
        .attr("href")
        .slice(1);
      if (!$existing_letter_groups_array.includes(letter)) {
        jQuery(this).addClass(
          "product-files-filter__alpha-filter-letter--disabled"
        );
      }
    });
  });
};
