export const LeadTimesAccordion = () => {
  jQuery(".lead-times-accordion").each(function() {
    var $block = jQuery(this),
      $group_titles = $block.find(".lead-times-accordion__group-title");
    $group_titles.on("click", function() {
      jQuery(this).toggleClass("lead-times-accordion__group-title--open");
      jQuery(this)
        .next(".lead-times-accordion__group-wrap")
        .slideToggle();
    });
  });
};
