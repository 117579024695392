export const LeavingCurrentSiteModal = (productUrl: string) => {
  jQuery.fancybox.open({
    src: jQuery(".LeavingCurrentSiteModal").get(0).outerHTML,
    type: "html",
    opts: {
      // baseClass: "LeavingCurrentSiteModal",
      buttons: ["close"],
      smallBtn: false,
      toolbar: true,
      idleTime: 0,
      touch: false,
      btnTpl: {
        close:
          '<button data-fancybox-close class="fancybox-button fancybox-button--close LeavingCurrentSiteModal_CloseBtn" title="{{CLOSE}}">' +
          '<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>' +
          "</button>"
      },
      afterShow: function(instance, current) {
        const modalContent = jQuery(current.$slide);
        const stayBtn = modalContent.find(
          ".LeavingCurrentSiteModal_ctaBtn___stay"
        );
        const goBtn = modalContent.find(".LeavingCurrentSiteModal_ctaBtn___go");
        stayBtn.on("click", function() {
          instance.close(null, null);
        });

        goBtn.on("click", function() {
          window.location.href = productUrl;
        });
      }
    }
  });
};
