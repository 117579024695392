export const StatementSlider = () => {
  jQuery(".statement-slider").each(function() {
    const photo_slider = jQuery(this).find(".statement-slider__photo-slider");
    const description_slider = jQuery(this).find(
      ".statement-slider__text-slider"
    );
    const is_static_text = description_slider.hasClass(
      "statement-slider__text-slider--static-text"
    );
    const caption_slider = jQuery(this).find(
      ".statement-slider__caption-slider"
    );
    const slider = jQuery(this).find(
      ".statement-slider__slider:not(.statement-slider__text-slider--static-text)"
    );
    const arrows_nav = jQuery(this).find(
      ".statement-slider__photo-slider-nav-contents"
    );

    import(
      /* webpackChunkName: "slick-carousel" */ "slick-carousel/slick/slick.min"
    )
      .then(() => {
        photo_slider.slick({
          slide: ".statement-slider__photo-slide",
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          infinite: false,
          swipeToSlide: true,
          rows: 0,
          adaptiveHeight: true,
          appendArrows: arrows_nav,
          asNavFor: slider
        });

        // build text slider if not set to static slider
        if (!is_static_text) {
          description_slider.slick({
            slide: ".statement-slider__text-slide",
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            infinite: false,
            swipeToSlide: true,
            rows: 0,
            asNavFor: slider
          });
        }

        caption_slider.slick({
          slide: ".statement-slider__caption-slide",
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          infinite: false,
          swipeToSlide: false,
          rows: 0,
          fade: true,
          asNavFor: slider
        });
      })
      .catch(err => {
        console.error(err);
      });
  });
};
