const Masonry = require("masonry-layout");
const InfiniteScroll = require("infinite-scroll");
const imagesLoaded = require("imagesloaded");
import "@fancyapps/fancybox/dist/jquery.fancybox.min";

/**
 * Masonary posts grid with infinite scroll and fancybox integration
 *
 * @export
 * @class GalleryMasonryGrid
 */
export class GalleryMasonryGrid {
  constructor() {
    // make imagesLoaded available for InfiniteScroll
    InfiniteScroll.imagesLoaded = imagesLoaded;

    this.$block = jQuery(".gallery-masonry-grid");

    if (this.$block.length) {
      this.initMasonry();
      this.initInfiniteScroll();
      this.initFancyBox();
    }
  }

  initMasonry() {
    this.msnry = new Masonry(".gallery-masonry-grid__grid", {
      itemSelector: ".gallery-masonry-grid__grid-item",
      columnWidth: ".gallery-masonry-grid__grid-sizer",
      percentPosition: true
    });

    this.imgLoad = new imagesLoaded(
      document.querySelector(".gallery-masonry-grid__grid")
    );
    // relayout masonry grid when images are loaded to make sure layout stays consistent
    this.imgLoad.on("progress", () => {
      this.msnry.layout();
    });
  }

  initInfiniteScroll = () => {
    const $status = this.$block.find(".gallery-masonry-grid__status");
    const $next = this.$block.find("a.next");
    if ($next.length) {
      this.$block.on("last.infiniteScroll", function() {
        $status.hide();
      });

      new InfiniteScroll(".gallery-masonry-grid__grid", {
        path: ".gallery-masonry-grid a.next",
        append: ".gallery-masonry-grid__grid-item",
        history: false,
        scrollThreshold: false,
        button: ".gallery-masonry-grid__view-more-btn",
        outlayer: this.msnry
        // debug: true
      });
    } else {
      $status.hide();
    }
  };

  initFancyBox() {
    // fancybox
    jQuery(".gallery-masonry-grid .gallery-modal__wrap").fancybox({
      selector: ".post-canvas-block .gallery-modal__container",
      baseClass: "gallery-modal",
      // keyboard: false,
      loop: true
    });

    jQuery(document).on(
      "click",
      ".gallery-masonry-grid .post-canvas-block__post-link",
      function(e) {
        e.preventDefault();
        let $fancy_content = jQuery(this).next(".gallery-modal__container");

        $fancy_content.trigger("click");
      }
    );

    jQuery(document).on(
      "click",
      ".gallery-modal .gallery-modal__nav-next",
      function() {
        var instance = jQuery.fancybox.getInstance();

        instance.next();
      }
    );

    jQuery(document).on(
      "click",
      ".gallery-modal .gallery-modal__nav-prev",
      function() {
        var instance = jQuery.fancybox.getInstance();
        instance.previous();
      }
    );
  }
}
