var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"FancyBoxImage\">\n  <div class=\"FancyBoxImage_Wrap\">\n    <div class=\"FancyBoxImage_PhotoCol\">\n      <div class=\"FancyBoxImage_PhotoWrap\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"img") || (depth0 != null ? lookupProperty(depth0,"img") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"img","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n        <div class=\"FancyBoxImage_PhotoDownloadLinkWrap\">\n          <a href=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"downloadLink") || (depth0 != null ? lookupProperty(depth0,"downloadLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadLink","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\" class=\"FancyBoxImage_PhotoDownloadLink\">\n            Download Image\n          </a>\n        </div>\n      </div>\n    </div>\n    <div class=\"FancyBoxImage_ContentCol\">\n      <div class=\"FancyBoxImage_Title\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"FancyBoxImage_Description\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});