require("imports-loader?define=>false!components/jquery.menu-aim");

import { TimelineLite } from "gsap/all";

export class UberMenu {
  $menu: JQuery;
  $topLevelMenus: JQuery;
  $globalHeader: JQuery;

  constructor($menu: JQuery) {
    this.$menu = jQuery($menu);
    this.$topLevelMenus = this.$menu.find(".uber-menu__top-level");
    this.$globalHeader = jQuery(".global-header");
    this.buildMenu();
  }

  private buildMenu(): void {
    // drop down menu animation timelines
    this.$topLevelMenus.each(function() {
      const $menuItem = jQuery(this);
      const $dropdownMenu = jQuery(this).find(".uber-menu__top-level-sub-menu");
      const uberMenuDropdown = new TimelineLite({ paused: true });

      uberMenuDropdown
        .addLabel("start", "+=0")
        .to($dropdownMenu, 0, { display: "block" }, "start")
        .from($dropdownMenu, 0.5, { autoAlpha: 0 }, "start+=.2");

      // attach timeline to dom so we can trigger it later
      $menuItem.data("gsap-animation", uberMenuDropdown);
    });

    const activateSubmenu = (row: JQuery): void => {
      const $row = jQuery(row);
      $row.addClass("uber-menu__top-level--active");
      if ($row.length) {
        $row
          .data("gsap-animation")
          .play()
          .timeScale(1);
      }
    };

    const deactivateSubmenu = (row: JQuery): void => {
      const $row = jQuery(row);

      $row.removeClass("uber-menu__top-level--active");
      if ($row.length) {
        $row
          .data("gsap-animation")
          .reverse()
          .timeScale(1.5);
      }
    };

    const enterMenu = (row: JQuery): void => {
      this.$globalHeader
        .data("dropdown_animation")
        .play()
        .timeScale(1);
    };

    const exitMenu = (row: JQuery): void => {
      this.$globalHeader
        .data("dropdown_animation")
        .reverse()
        .timeScale(1.5);
    };
    // const exitSubmenu = (row: JQuery): void => {};

    // initialize menuAim
    jQuery(".uber-menu__menu").menuAim({
      activateCallback: activateSubmenu, // fired on row activation
      deactivateCallback: deactivateSubmenu, // fired on row deactivation
      enterCallback: enterMenu, //fired on entering the entire menu
      exitMenuCallback: exitMenu, //fired on exiting the entire menu
      // exitCallback: exitSubmenu, //fired on exiting the row
      submenuDirection: "below",
      activationDelay: 0
    });
  }
}
