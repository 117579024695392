export const SalesLocationsTabs = () => {
  jQuery(".sales-locations-tabs").each(function() {
    let $this = jQuery(this),
      $tabs = $this.find(".sales-locations-tabs__category-link"),
      $tab_content = jQuery("[data-sales-locations-tab-content]");
    $tabs.on("click", function() {
      let $this = jQuery(this),
        tab_value = jQuery(this).attr("data-sales-locations-tab-button");
      if ($this.hasClass("sales-locations-tabs__category-link--active")) {
        return;
      } else {
        $this.addClass("sales-locations-tabs__category-link--active");
        $tabs
          .not($this)
          .removeClass("sales-locations-tabs__category-link--active");
        if (tab_value == "All") {
          $tab_content.show();
        } else {
          let $matching_tab = $tab_content.filter(function() {
            if (
              tab_value == jQuery(this).attr("data-sales-locations-tab-content")
            ) {
              return true;
            }
          });
          $tab_content.not($matching_tab).hide();
          $matching_tab.show();
        }
      }
    });
  });
};
