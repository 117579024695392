import { get_breakpoint } from "components/GlobalHelpers";

export const ProductCategoryFilter = () => {
  jQuery(".product-category-filter").each(function() {
    let $block = jQuery(this),
      $mobile_parent_cat_btn = $block.find(
        ".product-category-filter__mobile-menu-toggle-btn--primary"
      ),
      $mobile_sub_cat_btn = $block.find(
        ".product-category-filter__mobile-menu-toggle-btn--sub-cat"
      ),
      $dropdown_hover_btn = $block.find(
        ".product-category-filter__category-btn--dropdown"
      );

    // open top level product category menu
    $mobile_parent_cat_btn.on("click", function() {
      jQuery("body").trigger("product-filter-toggle", { target: "parent" });
    });

    // open sub level product category menu
    $mobile_sub_cat_btn.on("click", function() {
      jQuery("body").trigger("product-filter-toggle", { target: "sub-cat" });
    });

    let desktop_primary_menu_toggle = function(state = "toggle") {
      let button_width = $dropdown_hover_btn.outerWidth();

      // toggle the desktop primary category menu
      if (state == "toggle") {
        $dropdown_hover_btn.toggleClass(
          "product-category-filter__category-btn--dropdown-active"
        );
        $dropdown_hover_btn
          .next()
          .css("min-width", button_width + 10)
          .slideToggle();
      }
      // close the desktop primary category menu
      else if (state == "close") {
        $dropdown_hover_btn.removeClass(
          "product-category-filter__category-btn--dropdown-active"
        );
        $dropdown_hover_btn
          .next()
          .css("min-width", button_width + 10)
          .slideUp();
      }
    };

    // toggle desktop primary category button
    $dropdown_hover_btn.on("click", function(e) {
      if (get_breakpoint() == "md") {
        e.preventDefault();
        desktop_primary_menu_toggle();
      }
    });

    // close desktop primary category menu if clicked anywhere except the dropdown
    jQuery(document).on("click", function(event) {
      if (!get_breakpoint() == "md") {
        return;
      }
      if (!jQuery(event.target).closest($dropdown_hover_btn).length) {
        desktop_primary_menu_toggle("close");
      }
    });
  });

  jQuery("body").on("product-filter-toggle", function(e, data) {
    if (typeof data.target === "undefined" || data.target === null) {
      return;
    }
    let $mobile_parent_cat_btn = jQuery(
        ".product-category-filter__mobile-menu-toggle-btn--primary"
      ),
      $mobile_sub_cat_btn = jQuery(
        ".product-category-filter__mobile-menu-toggle-btn--sub-cat"
      ),
      $mobile_tag_btn = jQuery(".product-tag-filter__mobile-button"),
      $parent_category_menu = jQuery(
        ".product-category-filter__categories-wrap--primary-terms"
      ),
      $sub_category_menu = jQuery(
        ".product-category-filter__categories-wrap--main-bar"
      ),
      $tag_category_menu = jQuery(".product-tag-filter__tags-wrap");

    if (data.target == "parent") {
      if (data.action == "close") {
        $mobile_parent_cat_btn.removeClass(
          "product-category-filter__mobile-menu-toggle-btn--active"
        );
        $parent_category_menu.slideUp();
      } else {
        $mobile_parent_cat_btn.toggleClass(
          "product-category-filter__mobile-menu-toggle-btn--active"
        );
        if ($parent_category_menu.slideToggle().is(":visible")) {
          jQuery("body").trigger("product-filter-toggle", {
            target: "sub-cat",
            action: "close"
          });
          jQuery("body").trigger("product-filter-toggle", {
            target: "tag",
            action: "close"
          });
        }
      }
    } else if (data.target == "sub-cat") {
      if (data.action == "close") {
        $mobile_sub_cat_btn.removeClass(
          "product-category-filter__mobile-menu-toggle-btn--active"
        );
        $sub_category_menu.slideUp();
      } else {
        $mobile_sub_cat_btn.toggleClass(
          "product-category-filter__mobile-menu-toggle-btn--active"
        );
        if ($sub_category_menu.slideToggle().is(":visible")) {
          jQuery("body").trigger("product-filter-toggle", {
            target: "parent",
            action: "close"
          });
          jQuery("body").trigger("product-filter-toggle", {
            target: "tag",
            action: "close"
          });
        }
      }
    } else if (data.target == "tag") {
      if (data.action == "close") {
        $mobile_tag_btn.removeClass(
          "product-tag-filter__mobile-button--active"
        );
        $tag_category_menu.slideUp();
      } else {
        $mobile_tag_btn.toggleClass(
          "product-tag-filter__mobile-button--active"
        );
        if ($tag_category_menu.slideToggle().is(":visible")) {
          if (!get_breakpoint() == "md") {
            jQuery("body").trigger("product-filter-toggle", {
              target: "parent",
              action: "close"
            });
            jQuery("body").trigger("product-filter-toggle", {
              target: "sub-cat",
              action: "close"
            });
          }
        }
      }
    }
  });
};
