export const GravityFormBlock = () => {
  // show floating label animation
  function gravity_forms_floating_label($input, enable) {
    let $label;
    // regular field
    if ($input.parent().hasClass("ginput_container")) {
      $label = $input.closest(".gfield").find("label");
    }
    // complex field
    else {
      $label = $input.parent().find("label");
    }

    // if enable mode is on
    if (enable) {
      $label.addClass("gravity-form-block__floating-label");
    }
    // else do default checking if field has value in it.
    else {
      if ($input.val().length > 0) {
        $label.addClass("gravity-form-block__floating-label");
      } else {
        $label.removeClass("gravity-form-block__floating-label");
      }
    }
  }

  // init all the select2/material design js
  function gravity_forms_material_select2_init() {
    jQuery(".gravity-form-block").each(function() {
      let $block = jQuery(this),
        $inputs = $block.find("input[type=text],textarea"),
        $selects = $block.find("select");

      $inputs.on("focus", function() {
        gravity_forms_floating_label(jQuery(this), true);
      });

      $inputs.on("blur", function() {
        gravity_forms_floating_label(jQuery(this));
      });

      $inputs.on("change", function() {
        gravity_forms_floating_label(jQuery(this));
      });

      // select2
      $selects.attr("style", "width:100%");
      $selects.select2({
        // minimumResultsForSearch: Infinity,
        theme: "gravity-form-block",
        dropdownPosition: "below"
      });

      $selects.on("change.select2", function(e) {
        let $select = jQuery(e.target);
        gravity_forms_floating_label($select, true);
      });

      $selects.on("select2:open", function(e) {
        let $select = jQuery(e.target);
        gravity_forms_floating_label($select, true);
      });

      $selects.on("select2:close", function(e) {
        let $select = jQuery(e.target);
        gravity_forms_floating_label($select);
      });

      // check all inputs/selects and add floating labels
      $inputs.each(function() {
        gravity_forms_floating_label(jQuery(this));
      });

      $selects.each(function() {
        gravity_forms_floating_label(jQuery(this));
        // jQuery(this).trigger('change');
      });

      // add modifier to textarea labels
      jQuery(".ginput_container_textarea")
        .siblings("label")
        .addClass("gfield_label--textarea");
      // add modifier to select labels
      jQuery(".ginput_container_select")
        .siblings("label")
        .addClass("gfield_label--select");
      jQuery(".ginput_container_select")
        .parent()
        .addClass("gfield--select");

      // add modifier to fileinput labels
      jQuery(".ginput_container_fileupload")
        .siblings("label")
        .addClass("gfield_label--fileinput");

      // add modifier to all other selects
      jQuery(".gfield select")
        .siblings("label")
        .addClass("gfield_label--select");
    });
  }

  function build_finish_list() {
    // add the placeholders to the individual list items
    jQuery(".finish-list .gfield_list_cell").each(function() {
      let $this = jQuery(this),
        $tr = jQuery(
          "tr",
          $this
            .parent()
            .parent()
            .prev()
        ),
        i = $this.index();
      jQuery("input", $this).attr(
        "placeholder",
        jQuery("th:eq(" + i + ")", $tr).text()
      );
    });

    // click event for adding new row item
    jQuery(".finish-list").on("click", ".finish-list__add-item", function(e) {
      let $add_item_btn = jQuery(e.delegateTarget)
        .find(".add_list_item")
        .last();
      $add_item_btn.trigger("click");
    });
    jQuery(".finish-list").append(
      '<span class="finish-list__add-item">Add item</span>'
    );
  }

  jQuery(document).bind("gform_post_render", function() {
    import(/* webpackChunkName: "select2" */ "select2")
      .then(() => {
        gravity_forms_material_select2_init();
        build_finish_list();
      })
      .catch(err => {
        console.error(err);
      });
  });
};
