import { mainScrollMagicController } from "components/GlobalHelpers";

export const BreadCrumbs = () => {
  if (!document.getElementsByClassName("hero-slider-1").length) {
    return false;
  }
  import(/* webpackChunkName: "ScrollMagicGsap" */ "components/ScrollMagicGsap")
    .then(({ ScrollMagicGsap }) => {
      const $body = jQuery("body");

      // scroll animation for product detail page
      function product_detail_page_animation() {
        let $product_drawer = jQuery(".product-drawer"),
          $bread_crumbs = jQuery(".bread-crumbs--sticky-crumbs-js"),
          $trigger_element;

        if (jQuery("body").hasClass("single-product")) {
          if ($product_drawer.length) {
            $trigger_element = $product_drawer;
          }
          // if the drawer doesn't exist then it's a partners collections page so get the second content block
          else {
            $trigger_element = jQuery(
              ".content-blocks .content-blocks__block:nth-child(2)"
            );
          }
        } else if (jQuery("body").hasClass("tax-product_cat")) {
          $trigger_element = jQuery(".product-category-filter");
        } else {
          return;
        }

        // return if trigger element doesn't exist
        if ($trigger_element.length == 0) {
          return;
        }

        // change title font size + hide bread crumb link
        new ScrollMagicGsap.Scene({
          triggerElement: $trigger_element,
          triggerHook: 0.3,
          reverse: true
        })
          .setClassToggle(
            $bread_crumbs,
            "bread-crumbs--sticky-crumbs-scrolled-state-js"
          )
          // .addIndicators({ name: "hide crumbs" })
          .addTo(mainScrollMagicController);
      }

      // update the sticky crumb top value if body offset it updated
      function set_sticky_crumb_top() {
        const $sticky_crumbs_wrap = jQuery(".bread-crumbs__wrap");
        $body.on("offset_updated", function() {
          const body_offset =
            parseInt($body.css("padding-top")) +
            parseInt($body.css("margin-top"));
          $sticky_crumbs_wrap.css("top", body_offset + "px");
        });
      }
      if (jQuery(".bread-crumbs--sticky-crumbs-js").length) {
        product_detail_page_animation();
        set_sticky_crumb_top();
      }
    })
    .catch(err => {
      console.error(err);
    });
};
