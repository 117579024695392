import { containsAll } from "components/GlobalHelpers";

export const ProductTagFilter = () => {
  jQuery(".product-tag-filter").each(function() {
    var $block = jQuery(this),
      $mobile_btn = jQuery(this).find(".product-tag-filter__mobile-button"),
      $check_boxes = jQuery(this).find(".product-tag-filter__tag-checkbox"),
      $tag_filter_count = jQuery(this).find(".product-tag-filter__tags-count"),
      $clear_btn = jQuery(this).find(".product-tag-filter__tags-clear-btn"),
      $all_products = jQuery(".products-listing-block__product-block");

    $mobile_btn.on("click", function() {
      jQuery("body").trigger("product-filter-toggle", { target: "tag" });
    });

    jQuery(this).on("update-tag-filter", function() {
      var filter_array = [],
        count = 0;
      $check_boxes.each(function() {
        if (jQuery(this).is(":checked")) {
          filter_array.push(jQuery(this).val());
          count++;
        }
      });

      // show all products before filtering again
      $all_products.show();

      // update count
      $tag_filter_count.html(count);

      $all_products
        .filter(function() {
          var product_tags = JSON.parse(jQuery(this).attr("data-tags"));
          // console.log(filter_array);
          // console.log(product_tags);
          // console.log(jQuery.inArray('gsa', filter_array));
          // console.log(filter_array);
          if (!containsAll(filter_array, product_tags)) {
            return true;
          }
        })
        .hide();
    });

    jQuery(".product-tag-filter__tag-checkbox").change(function() {
      $block.trigger("update-tag-filter");
    });

    // clear all tag filters
    $clear_btn.on("click", function() {
      $check_boxes.prop("checked", false);
      $block.trigger("update-tag-filter");
    });
  });
};
