export const HeroSlider1 = () => {
  if (jQuery(".hero-slider-1").length) {
    import("slick-carousel/slick/slick.min")
      .then(() => {
        jQuery(".hero-slider-1").each(function() {
          var $slider = jQuery(this).find(".hero-slider-1__slider"),
            arrows_nav = jQuery(this).find(
              ".hero-slider-1__slider-nav-contents"
            ),
            $slide_count = jQuery(this).find(
              ".hero-slider-1__slider-nav-current-count"
            ),
            center_mode = jQuery(this).hasClass("hero-slider-1--center-mode");

          $slider.slick({
            slide: ".hero-slider-1__slide",
            lazyLoad: "progressive",
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            swipeToSlide: true,
            rows: 0,
            appendArrows: arrows_nav,
            centerMode: center_mode ? true : false,
            centerPadding: "280px",
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  centerMode: false
                }
              }
            ]
          });

          $slider.on("init reInit afterChange", function(
            event,
            slick,
            currentSlide
          ) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            $slide_count.text(i);
          });
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
};
