import Cookies from "js-cookie";
import { get_breakpoint } from "components/GlobalHelpers";
import { throttled_resize } from "components/GlobalHelpers";
/**
 * show hide logic for displaying the accept cookies notice
 */
export const CookiesNotice = () => {
  // set variables
  let $block = jQuery(".cookies_notice"),
    $close_btn = $block.find(".cookies_notice__close-btn"),
    $top_nav_wrap = jQuery(".top-nav-wrap"),
    $body = jQuery("body"),
    $admin_bar = jQuery("#wpadminbar");

  /**
   * show/hide notice
   * @param  {string} $toggle accepts 'show' or 'hide'
   */
  function notice_toggle($toggle) {
    let block_px_height = $block.outerHeight() + "px";

    if ($block.hasClass("cookies_notice--hide-toggled")) {
      // if it was just hide toggled then do nothing
    } else if ($toggle == "show") {
      $block.addClass("cookies_notice--visible");
      if (get_breakpoint() == "md") {
        $body.css("margin-top", block_px_height);
        if ($admin_bar.length) {
          block_px_height =
            parseInt(block_px_height) +
            parseInt($admin_bar.outerHeight()) +
            "px";
        }
        $top_nav_wrap.css("top", block_px_height);
      } else {
        $body.removeAttr("style");
        $top_nav_wrap.removeAttr("style");
      }
    } else if ($toggle == "hide") {
      $block.removeClass("cookies_notice--visible");
      $block.addClass("cookies_notice--hide-toggled");
      $body.removeAttr("style");
      $top_nav_wrap.removeAttr("style");
    }

    $body.trigger("offset_updated");

    return;
  }

  /**
   * set coalesse_accepts_cookies for a year and hide notice
   */
  function set_cookie() {
    Cookies.set("coalesse_accepts_cookies", true, { expires: 365 });
    notice_toggle("hide");
  }

  /**
   * check if user has already accepted cookies
   * @return {boolean} true if they accepted. false if not.
   */
  function user_accepts_cookies() {
    if (Cookies.get("coalesse_accepts_cookies") == "true") {
      return true;
    } else {
      return false;
    }
  }

  /**
   * set accept cookie event
   */
  function set_events() {
    // set cookie and hide
    $close_btn.on("click", function() {
      set_cookie();
    });
  }

  function init() {
    // if user accepts cookies then end
    if (user_accepts_cookies()) {
      return;
    }

    // else show notice and set events
    else {
      set_events();
      notice_toggle("show");
      // set delayed to reset spacing if window is resized
      throttled_resize(250, function() {
        notice_toggle("show");
      });
    }
  }
  init();
};
