import { TimelineLite } from "gsap/all";

export const RegionBar = () => {
  jQuery(".region-bar").each(function() {
    let $block = jQuery(this),
      $dropdown_trigger = $block.find(".region-bar__dropdown-trigger-js"),
      $dropdown = $block.find(".region-bar__regions-dropdown"),
      $region_text = $block.find(".region-bar__region-text"),
      $region_links = $block.find(".region-bar__region-link"),
      dropdown_animation = new TimelineLite({
        paused: true,
        onReverseComplete: function() {
          $dropdown_trigger.removeClass(
            "region-bar__dropdown-trigger-js--active"
          );
        },
        onComplete: function() {
          $dropdown_trigger.addClass("region-bar__dropdown-trigger-js--active");
        }
      });

    /**
     * region dropdown animation
     */
    dropdown_animation
      .addLabel("start")
      .fromTo(
        $dropdown,
        0.2,
        { height: 0 },
        { height: "7.8125rem", ease: Power1.easeOut },
        "start"
      )
      .from($region_links, 0.1, { autoAlpha: 0 }, "start+=.1");

    $dropdown.data("gsap-animation", dropdown_animation);

    // region dropdown hover
    $dropdown_trigger.hover(
      function() {
        $dropdown
          .data("gsap-animation")
          .play()
          .timeScale(1);
      },
      function() {
        $dropdown
          .data("gsap-animation")
          .reverse()
          .timeScale(1.5);
      }
    );
    $region_text.on("click", function() {
      if (
        $dropdown_trigger.hasClass("region-bar__dropdown-trigger-js--active") &&
        $dropdown_trigger.is(":hover")
      ) {
        $dropdown_trigger.trigger("mouseleave");
      } else {
        $dropdown_trigger.trigger("mouseenter");
      }
    });
  });
};
