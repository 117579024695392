// import { ScrollMagicGsap } from "components/ScrollMagicGsap";
import ScrollMagic from "scrollmagic/scrollmagic/uncompressed/ScrollMagic";

export const mainScrollMagicController = new ScrollMagic.Controller();

export function throttled_resize(delayInMs, callback) {
  var resizeTimer;
  jQuery(window).on("resize", function(e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      callback(e);
    }, delayInMs);
  });
}

export function get_breakpoint() {
  if (window.matchMedia("(min-width: 992px)").matches) {
    return "md";
  } else {
    return "mobile";
  }
}

// check if array contains multiple values
export function containsAll(needles, haystack) {
  for (var i = 0, len = needles.length; i < len; i++) {
    if (jQuery.inArray(needles[i], haystack) == -1) return false;
  }
  return true;
}

// get URL parameter queries
export function get_url_parameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? false : sParameterName[1];
    }
  }
}
